import logo from './logo.svg';
import './App.css';
import HomeComponent from './pages/home/home';
import HeaderComponent from './pages/home/header/header';
import FooterComponent from './pages/home/footer/footer';
import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
import ErrorComponent from './pages/home/404page/error';
import PaymentSuccessComponent from './pages/home/paymentSuccess';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <div><HeaderComponent /></div>
        <Routes>
          <Route path='/' element={<HomeComponent />} />
          <Route path="/errorPage" element={<ErrorComponent />} />
          <Route path='/success' element={<PaymentSuccessComponent />} />
        </Routes>
        <div><FooterComponent /></div>
      </BrowserRouter>

    </div>
  );
}

export default App;
