import axios from "axios"
const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL,
});


const uuid = Math.floor(Math.random() * (1000000000000001 - 1000000001) + 1000000001); // The maximum is exclusive and the minimum is inclusive

export const get = (url) => {
    return instance.get(url, {
        headers: {
            client_id: process.env.REACT_APP_client_id,
            client_secret: process.env.REACT_APP_client_secret,
            program_id: process.env.REACT_APP_program_id,
            UUID: uuid,
            AppID: process.env.REACT_APP_AppID,
            Language: process.env.REACT_APP_Language,
            bypassSession: "true"
        }
    }).then((res) => {
        console.log('api response', res)
        if ((res.status === 400 || res.status === 200) && res.data.errorMessage === "Invalid Token") {
            console.log('api status', res.status, res.data)
            //alert('Session Expired. Navigating to Landing Page'
        }
        return res
    }).catch((err) => {
        console.log('api error', err)
        if (err.response.status === 400 && err.response.data.errorMessage === "Invalid Token") {
            console.log('api status', err.response.status, err.response.data)
            //alert('Session Expired. Navigating to Landing Page')

        }
        return err
    })
}

export const post = (url, postdata) => {
    return instance.post(url, postdata, {
        headers: {
            "Content-Type": "application/json",
            client_id: process.env.REACT_APP_client_id,
            client_secret: process.env.REACT_APP_client_secret,
            program_id: process.env.REACT_APP_program_id,
            UUID: uuid,
            AppID: process.env.REACT_APP_AppID,
            Language: process.env.REACT_APP_Language,
            // 'Access-Control-Allow-Origin': '*'
        }
    }).then((res) => {
        console.log('api response', res)
        if ((res.status === 400 || res.status === 200) && res.data.errorMessage === "Invalid Token") {
            console.log('api status', res.status, res.data)

        }
        return res
    })
        .catch((err) => {
            console.log('api error', err)
            if (err.response.status === 400 && err.response.data.errorMessage === "Invalid Token") {
                console.log('api status', err.response.status, err.response.data)
            }
            return err
        })
}