import React from 'react';

function ErrorComponent() {

    return (
        <>
            <h1>Payment failed</h1>
            <p>Sorry you don't have the membership, please use valid membership</p>
        </>
    )
}
export default ErrorComponent;