import React, { useEffect, useState } from 'react';
import { get, post } from "../../../config/index";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import "./form.css";

function FormComponent(props) {
    let navigate = useNavigate();
    //from fields initialization
    const [userEmail, setUserEmail] = useState("");
    const [userSalu, setUserSalu] = useState("");
    const [userMobPin, setUserMobPin] = useState("");
    const [userFname, setUserFname] = useState("");
    const [userLname, setUserLname] = useState("");
    const [userMobile, setUserMobile] = useState("");
    const [checked, setChecked] = useState(false);

    //form fields error
    const [userFnameErr, setUserFnameErr] = useState(false);
    const [userLnameErr, setUserLnameErr] = useState(false);
    const [usermobileErr, setUsermobileErr] = useState(false);
    const [userEmailErr, setUserEmailErr] = useState(false);

    //modal 
    const [modalOpen, setModalOpen] = useState(false);

    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            width: 400,
        },
    };

    useEffect(() => {
        setTimeout(() => {
            setUserEmail("Lovish")
            setUserSalu("Mr")
            setUserFname("la@hha.com")
            setUserLname("asxa")
            setUserMobile("2929292929")
        }, 2000)
    }, [])

    function keyPressValidationFname(e) {
        console.log(e.keyCode)
        setUserFname(e.target.value);
        if ((e.keyCode > 64 && e.keyCode < 91) || (e.keyCode > 96 && e.keyCode < 123)) {
            setUserFnameErr(false)
        } else {
            setUserFnameErr(true)
        }
    }

    function keyPressValidationLname(e) {
        setUserLname(e.target.value)
        if ((e.keyCode > 64 && e.keyCode < 91) || (e.keyCode > 96 && e.keyCode < 123)) {

            setUserLnameErr(false)
        } else {
            setUserLnameErr(true)
        }

    }

    function keyPressValidationMobile(e) {
        setUserMobile(e.target.value);
        if (e.keyCode >= 48 && e.keyCode <= 57) {
            setUsermobileErr(false);
        } else {
            setUsermobileErr(true);
        }
    }

    function validateEmail(e) {
        setUserEmail(e.target.value);
        const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (regex.test(e.target.value)) {
            setUserEmailErr(false);
        } else {
            setUserEmailErr(true);
        }
    }

    const handleChangeChecked = () => {
        setChecked(!checked);
    };

    async function submitFormData() {
        if ((userEmail === "" || userEmailErr === true) || (userLname === "" || userLnameErr === true) || (userFname === "" || userFnameErr === true) || (userMobile === "" || usermobileErr === true) || checked === false) {
            setModalOpen(true)
            return false;
        } else {
            // alert(userEmail + "," + userFname + "," + userMobile + "," + userLname + "," + checked)
            function validatePromocode() {
                props.spinner(true)
                return new Promise((result) => {
                    let res = get('/validateSignupPromocode?tx=signup&promocode=HDCMTUC50');
                    result(res)
                })

            }

            function makePayment(customerSetId) {
                let payload = {
                    "memberSfid": "001C500000AFZkTIAX",//(memeberSfid from Reference Number API)
                    "transactionType": "Membership-Buy",
                    "membershipType": customerSetId,//"customerSetId": "a0J2j000001czXPEAY"(From Validate Signup Promocode API)
                    "membershipTypeOffer": [],
                    "consumerId": "695745",//(memeberId from Reference Number API)
                    "membershipNumber": "",
                    "amount": "0",//(Always Zero)
                    "promocode": "HDCMTUC50",//defaulut promocode
                    "discount": "0",//(Always Zero)
                    "gstNumber": "",
                    "gstsfid": "",
                    "gstCompanyName": "",
                    "address": {
                        "addressType": "",
                        "country": "",
                        "addressLine1": "",
                        "addressLine2": "",
                        "city": "",
                        "state": "",
                        "pinCode": ""
                    },
                    "ipAddress": "164.0.0.3",
                    "netAmount": "0",//(Always Zero)
                    "finalAmount": "0",//(Always Zero)
                    "gstAmount": "0",//(Always Zero)
                    "quantity": "1.0",
                    "currency": "INR"
                }

                return post("/payment", payload);

            }
            const dataRes = await validatePromocode();
            if (dataRes.status === 200 && dataRes.data.statusCode === 200) {
                let customerSetId = dataRes.data.customerSetId;
                let payentResult = await makePayment(customerSetId);
                props.spinner(false)
                if (payentResult.response.data.errorCode) {
                    navigate('/errorPage');
                } else if (payentResult.status) {
                    navigate('/success')
                }

            }
        }
    }
    return (
        <>
            <form className="rw360Form  mt-5">
                <div className="row">
                    <div className="col-12 mt_20">
                        <h3 className="text-center mb-5">Lorem Ipsum</h3>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-2 col-4">
                        {userSalu != "" ? <>
                            <input type="text" className={`form-control`} id="personSalutation" name="title"
                                defaultValue={userSalu ? userSalu : ""} readOnly={userSalu != "" ? true : false} required />
                        </> : <>
                            <select className="form-control" id="personSalutation">
                                <option value="Title">Title</option>
                                <option value="Mr">Mr</option>
                                <option value="Ms">Ms</option>
                            </select>
                        </>}
                    </div>

                    <div className="col-md-5 col-8">
                        <input type="text" className={`form-control ${userFnameErr === true ? 'errClass' : 'nonErrorClass'}`} id="fname" name="fname"
                            placeholder="First Name" defaultValue={userFname ? userFname : ""} readOnly={userFname != "" ? true : false} pattern="^[a-zA-Z]*$" onKeyDown={e => keyPressValidationFname(e)} required />
                    </div>

                    <div className="col-md-5 col-12">
                        <input type="text" className={`form-control ${userLnameErr === true ? 'errClass' : 'nonErrorClass'}`} id="lname" name="lname"
                            placeholder="Last Name" defaultValue={userLname ? userLname : ""} readOnly={userLname != "" ? true : false} onKeyDown={e => keyPressValidationLname(e)} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-2 col-4">
                        <select className="form-control" id="mCountryCode">
                            <option value="+91">+91</option>
                            <option value="+22">22</option>
                            <option value="+23">23</option>
                            <option value="+24">24</option>
                        </select>
                    </div>

                    <div className="col-md-5 col-8">
                        <input type="text" className={`form-control ${usermobileErr === true ? 'errClass' : 'nonErrorClass'}`} id="mobileNo" name="mobileNo"
                            placeholder="Mobile" maxLength="10" defaultValue={userMobile ? userMobile : ""} readOnly={userMobile != "" ? true : false} onKeyDown={keyPressValidationMobile} />
                    </div>

                    <div className="col-md-5 col-12">
                        <input type="email" className={`form-control ${userEmailErr === true ? 'errClass' : 'nonErrorClass'}`} id="emailId" name="emailId" defaultValue={userEmail ? userEmail : ""} readOnly={userEmail != "" ? true : false} onChange={validateEmail} placeholder="Email" />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="checkbox justify_align">
                            <label className='form-check-label'>
                                <input type="checkbox" className="form-check-input" id="bankEnrollmentUserConsent" name="remember" checked={checked} onChange={handleChangeChecked} />
                                By offering the content above, I give permission to Club Marriott to make contact with me to receive more information and agree to the <a href='#'> Terms &amp; Conditions</a> and the <a href="#">Privacy Policy</a> on the website.

                            </label>
                        </div>
                    </div>
                </div>



                <div className="row">
                    <div className='col-md-12 captcha'>
                        <img src='https://utopia.fans/wp-content/webp-express/webp-images/uploads/2021/09/Types-of-CAPTCHA.jpg.webp' />
                    </div>
                    <div className="col-sm-12 mb-4">
                        <button type="button" id="submitBecomeMemberBtnInfinia"
                            className="cm-button infinia-button primary mt-30 mt-25-sm mt-5" onClick={submitFormData}>Submit</button>
                    </div>
                </div>

            </form>
            <Modal isOpen={modalOpen} onRequestClose={() => setModalOpen(false)}
                ariaHideApp={false}
                style={customStyles}>

                <div className='modal-body'>Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum</div>

                <div className='text-center'>
                    <button className="cm-button primary" onClick={() => setModalOpen(false)}>Close</button>
                </div>
            </Modal>
        </>
    );
}

export default FormComponent;