import React, { useEffect, useState } from 'react';
import './home.css';
import FormComponent from './rw360form/form';
import spaImg from "../../assets/spa.png";
import stayImg from "../../assets/stay.png";
import diningImg from "../../assets/dining.png";
import roomUpgrade from "../../assets/room-upgrade.jpg";
import offonStay from "../../assets/off-on-stay.jpg";
import parHotel1 from "../../assets/participating-hotels-1.jpg";
import parHotel2 from "../../assets/participating-hotels-2.png";
import { get, getUUID } from "../../config/index"
import LoadingSpinner from './loadingSpinner';



function HomeComponent() {
    const [loader, setLoader] = useState(false)

    function spinner(state) {
        setLoader(state)
    }

    useEffect(() => {
        spinner(true);
        setTimeout(() => {
            spinner(false);
        }, 2000)
        const res = get('/rw360/validatereferencenumber?rw360refernceNumber=9876554');
    }, [])
    return (
        <>
            {loader === true ? <LoadingSpinner /> : <>
                <div><FormComponent spinner={spinner} /></div>
                <section className='middle-section'>
                    <div className='container mt-5'>
                        <div className='row'>
                            <div className='col'>
                                <h1>Enjoy the Club Marriott Experience</h1>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been <br />the industry’s standard dummy text ever since the 1500s, </p>
                            </div>
                        </div>
                        <section className='marriott-experience'>
                            <div className='row'>
                                <div className='col-md-4'>
                                    <img src={diningImg} className='img-fluid' />
                                    <h1>Up to 25% off on Dining</h1>
                                    <p>Up to 25% off on Food & Beverage at participating Marriott Hotels in India for a maximum of 20 guests.</p>
                                </div>
                                <div className='col-md-4'>
                                    <img src={stayImg} className='img-fluid' />
                                    <h1>Up to 20% off on Stay</h1>
                                    <p>Up to a 20% off on Best Available Rate for Rooms at participating Marriott Hotels in India.</p>
                                </div>
                                <div className='col-md-4'>
                                    <img src={spaImg} className='img-fluid' />
                                    <h1>Up to 20% off on Spa</h1>
                                    <p>Up to 20% off on Spa services at select participating Marriott Hotels in India.</p>
                                </div>
                            </div>
                        </section>

                        <section className='room-facility mt-5'>
                            <div className='row'>
                                <div className='col-12'>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy</p>
                                </div>
                                <div className='col-md-6'>
                                    <img src={offonStay} className='img-fluid' />
                                    <h3>30% off on Stay</h3>
                                    <p>1 (one) certificate offering 30% off on the stay experience at participating hotels (in India)- off on Best Available Rate (BAR) for a maximum of 2 nights stay – subject to availability on Marriott online codes for a maximum of 2 (Two) consecutive nights.</p>
                                </div>
                                <div className='col-md-6'>
                                    <img src={roomUpgrade} className='img-fluid' />
                                    <h3>Room Upgrade</h3>
                                    <p>1 (One) certificate offering an upgrade to next category of room, subject to availability at check in – (max. 2 nights stay). This certificate is valid at Participating hotels in India</p>
                                </div>
                            </div>
                        </section>

                        <section className='participating-hotels mt-5'>
                            <div className='row'>

                                <div className='col-md-6'>
                                    <img src={parHotel1} className='img-fluid' />
                                    <p>Explore our list of Participating Hotels</p>
                                </div>
                                <div className='col-md-6'>
                                    <img src={parHotel2} className='img-fluid' />
                                    <p>Explore our list of Participating Hotels</p>
                                </div>
                            </div>
                        </section>

                    </div>

                </section>
                <section className='term-and-contition'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <h3>Terms and Conditions</h3>
                                <ol>
                                    <li>Membership can be availed only by Primary cardholders on providing a valid Email and Mobile number registered on your Amazon employees.</li>
                                    <li>On successful registration, the Membership Number will be emailed to you instantly.</li>
                                    <li> The membership will be available to you on the Club Marriott South Asia App.</li>
                                    <li>The membership will be valid for a period of 1 year from the date of issuance. For a detailed list of our terms and conditions, please click here.</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
            </>}
        </>
    );
}

export default HomeComponent;