import React from 'react';
import './footer.css';

function FooterComponent() {
    return (  
    <>
    
    <section className="mt-3">
         <div className="container">
            <div className="row">
               <div className="cm-wrapper">
                  <div className="custom-hotel-list ">
                     <div className="custom-hotel-list-logo">
                        <a target="_blank">
                        <img alt='Hello' src='https://www.clubmarriott.in/content/dam/tlc-web-platform/marriott-international.png'/>
                        </a>
                     </div>
                     <div>
                        <div>
                           <div>
                              <div className="custom-hotel-row">
                                 <a
    href="https://www.ritzcarlton.com/" target="_blank">
    <img alt='Ritz Carlton Hotels - Club Marriott South Asia'
src="https://www.clubmarriott.in/content/dam/tlc-website/Logos/ritz-carlton.png"/>
</a>
                                 <a
href="https://st-regis.marriott.com/" target="_blank">
    <img src="https://www.clubmarriott.in/content/dam/tlc-website/Logos/st-regis.png"
alt="St. Regis Hotels - Club Marriott South Asia"/></a>
                                 <a
href="https://w-hotels.marriott.com/" target="_blank"><img
src="https://www.clubmarriott.in/content/dam/tlc-website/Logos/w-hotels.png"
alt="W Hotels - Club Marriott South Asia"/></a>
                                 <a
href="https://jw-marriott.marriott.com/" target="_blank"><img
src="https://www.clubmarriott.in/content/dam/tlc-website/Logos/jw-marriott.png"
alt="JW Marriott Hotels - Club Marriott South Asia"/></a>
                              </div>
                           </div>
                        </div>
                        <div>
                           <div>
                              <div className="custom-hotel-row">
                                 <a
href="https://marriott-hotels.marriott.com/" target="_blank"><img
src="https://www.clubmarriott.in/content/dam/tlc-website/Logos/marriott-logo.png"
alt="Marriott Hotels"/></a>
                                 <a
href="https://sheraton.marriott.com/" target="_blank"><img
src="https://www.clubmarriott.in/content/dam/tlc-website/Logos/sheraton.png"
alt="Sheraton Hotels - Club Marriott South Asia"/></a>
                                 <a
href="https://westin.marriott.com/" target="_blank"><img
src="https://www.clubmarriott.in/content/dam/tlc-website/Logos/westin.png"
alt="Westin Hotels - Club Marriott South Asia"/></a>
                                 <a
href="https://le-meridien.marriott.com/" target="_blank"><img
src="https://www.clubmarriott.in/content/dam/tlc-website/Logos/le-meridien.png"
alt="Le Meridien Hotels - Club Marriott South Asia"/></a>
                                 <a
href="https://renaissance-hotels.marriott.com/" target="_blank"><img
src="https://www.clubmarriott.in/content/dam/tlc-website/Logos/renaissance.png"
alt="Renaissance Hotels - Club Marriott South Asia"/></a>
                              </div>
                           </div>
                        </div>
                        <div>
                           <div>
                              <div className="custom-hotel-row">
                                 <a
href="https://courtyard.marriott.com/" target="_blank"><img
src="https://www.clubmarriott.in/content/dam/tlc-website/Logos/courtyard.png"
alt="Courtyard by Marriott - Club Marriott South Asia"/></a>
                                 <a
href="https://four-points.marriott.com/" target="_blank"><img
src="https://www.clubmarriott.in/content/dam/tlc-website/Logos/four-points.png"
alt="Four Points by Sheraton - Club Marriott South Asia"/></a>
                                 <a
href="https://fairfield.marriott.com/" target="_blank"><img
src="https://www.clubmarriott.in/content/dam/tlc-website/Logos/fairfield-mariott.png"
alt="FairFeild Hotels - Club Marriott South Asia"/></a>
                                 <a
href="https://aloft-hotels.marriott.com/" target="_blank"><img
src="https://www.clubmarriott.in/content/dam/tlc-website/Logos/aloft.png"
alt="Aloft Hotels - Club Marriott South Asia"/></a>
                              </div>
                           </div>
                        </div>
                        <div>
                           <div>
                              <div className="custom-hotel-row">
                                 <a
href="https://www.marriott.com/executive-apartments/travel.mi"
target="_blank"><img
src="https://www.clubmarriott.in/content/dam/tlc-website/Logos/marriott-exec-apartments.png"
alt="Marriott Executive Apartments - Club Marriott South Asia"/></a>
                                 <a
href="https://the-luxury-collection.marriott.com/"
target="_blank"><img
src="https://www.clubmarriott.in/content/dam/tlc-website/Logos/lux-col.png"
alt="The Luxury Collection by Marriott International"/></a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
    </>
    );
}

export default FooterComponent;