import React from 'react';

function PaymentSuccessComponent() {

    return (
        <>
            <h1>Payment successful</h1>
            <p>Thanks for selecting Club Marriott</p>
        </>
    )
}
export default PaymentSuccessComponent;