import React from 'react';
import './header.css';
import headerImg from  "../../../assets/header-banner.png";

function HeaderComponent() {
    return (
    <>
               <div className='container-fluid '>
                <div className='row'>
                    <div className='col-md-12 no-marginLR '>
                        <img className='mt-3 mb-3' width={'200'} src='https://stage.clubmarriott.in/content/dam/tlc-website/logo-cm.svg'/>
                        <img className='img-fluid mh-100'  src={headerImg}/>
                    </div>
                </div>
               </div>
    </>);
    
}


export default HeaderComponent;